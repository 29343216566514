export default [
  {
    image: "Group 185-1.svg",
    title: "Sign Up and get instant SEO Report",
    imageAlt: "Sign Up and get instant SEO Report",
  },
  {
    image: "Group 185-2.svg",
    title: "Get your easy SEO plan",
    imageAlt: "Get your easy SEO plan",
  },
  {
    image: "Group 185.svg",
    title: "Follow the simple step-by-step instructions",
    imageAlt: "Follow the simple step-by-step instructions\n",
  },
];
