export default [
  {
    name: "Heather Figi",
    message:
      "marketgoo made the complicated simple for me. I never knew where to start with SEO until I started using this service. Literally, I more than doubled my traffic when I started using this.",
    image: "/codeguard/Heather Figi.jpg",
  },
  {
    name: "Ian Glass",
    message:
      "Without marketgoo I would never have been able to have my website on the first page of google for all the keywords that are linked to my business, its ease of use and the customer service when I’ve needed help has been first class.",
    image: "/codeguard/Ian Glass.jpg",
  },
  {
    name: "Simon Saleh",
    message:
      "Such a simple reliable platform! It’s helped me see where we stand next to our local competitors. Its part of our day to day. The best feature is the keyword tool.",
    image: "/codeguard/Simon Saleh.jpg",
  },
  {
    name: "Svein Koningen",
    message:
      "marketgoo reports give me everything I need to know for my site’s SEO. I am constantly fine tuning my site to work towards a higher rating. I read various marketgoo reports then immediately work out how to implement recommendations.",
    image: "/codeguard/Svein Koningen.jpg",
  },
];
