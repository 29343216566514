import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

// import Arrow from "../images/arrow.svg";
function imageLeft(props) {
  return (
    <section className="px-32 mobile:px-2 tablet:px-4 laptop:px-16">
      <div className=" flex laptop:my-10 bg-white mobile:flex-col ">
        <div className="w-1/2 laptop:w-2/3 ml-5 mt-10 mobile:m-0 mobile:text-center tablet:text-center mobile:w-full tablet:w-full">
          {/* <img className="mobile:mt-8  mobile:p-4 m-auto" src={props.image}></img> */}
          {props.image}
        </div>

        <div className="ml-8 w-1/2 mobile:ml-0 tablet:ml-0 m-auto mobile:text-center mobile:px-6 tablet:px-4 mobile:m-4 mobile:w-full tablet:w-full ">
          <h2 className=" tablet:text-xl font-extrabold capitalize text-3xl mb-3 ">
            {props.header}
          </h2>
          <ul className="text-gray-700 font-semibold list-disc list-inside my-6 mobile:mx-4 text-left text-sm">
            {props.listItems}
          </ul>
          <p className="uppercase font-bold text-gray-500">
            {props.smallHeader}
          </p>
          <div className="font-light my-3 mobile:w-full mobile:pl-4 mobile:pr-8  tablet:w-full mobile:text-center ">
            {props.desc}
          </div>
          <div className={` mx-auto  ${props.hideLearnMore}`}>
            <Link
              to={props.btnLink ? props.btnLink : "/"}
              className="inline-block text-blue-600 font-black uppercase text-md underline mr-1 min-w-min"
            >
              {props.btnText}
            </Link>
            <img
              className={` inline-block ${props.hideArrow} `}
              src={"/arrow.svg"}
            />
          </div>
          <div className={` mx-auto my-6 flex flex-row ${props.hideShopNow}`}>
            <Link
              to={"/ssl"}
              className="text-black-600 font-semibold uppercase text-md rounded-full border-2 border-black py-2 px-6 mr-2"
            >
              shop now
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default imageLeft;
imageLeft.propTypes = {
  image: PropTypes.object.isRequired, // must be a string and defined
  header: PropTypes.any.isRequired, // must be a string and defined
  desc: PropTypes.any.isRequired, // must be a string and defined
  listItems: PropTypes.any, // must be a string and defined
  btnLink: PropTypes.string, // must be a string and defined
  btnText: PropTypes.string, // must be a string and defined
  hideLearnMore: PropTypes.string, // must be a string and defined
  hideShopNow: PropTypes.string, // must be a string and defined
  hideArrow: PropTypes.string, // must be a string and defined: PropTypes.string, // must be a string and defined
};
