import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Landing from "../components/landingSection";
import Step from "../components/featureBlock";
import ImageLeft from "../components/imageLeft";
// import ImageRight from "../components/imageRight";
import Steps from "../DB/marketgoo-steps";
// import AccordionContent from "../DB/marketgoo-FAQ";
import Accordion from "../components/accordion";
// import Packages from "../DB/plan-card-marketgoo";
import PlanCard from "../components/plan_card";
import LI from "../components/plan-li";
import IndexSlides from "../DB/marketgoo_testmonials_slides";
import Reviews from "../components/reviews";
import Image from "../components/imageProcessing";
import { graphql, useStaticQuery } from "gatsby";
// import Image from "../components/imageProcessing";
/* eslint react/prop-types: 0 */
const AccordionContent = [
	{
		title: "Should I choose Lite or Pro?",
		// eslint-disable-next-line react/react-in-jsx-scope
		content: (
			<a
				target="_blank"
				rel="noreferrer"
				href="https://vimeo.com/whmcs/marketgoo-lite-vs-pro"
			>
				Click to see a video comparison of the Plans
			</a>
		),
	},
	{
		title: "Does marketgoo make the recommended changes or do I?",
		content:
			"marketgoo is a Do-it-Yourself tool, so while we help you with analysing your site and giving recommendations, along with tasks and instructions for your to optimize your site, we don't make these changes for you.",
	},
	{
		title: "Why do I need SEO?",
		content:
			"You work on your SEO in order to improve your site's rankings in search results. This leads to attracting more traffic - and ideally, to convert that traffic into customers and leads.",
	},
];
function marketgoo() {
	const data = useStaticQuery(graphql`
		query marketgoQuery {
			allProducts(filter: { productGroupID: { eq: 9 } }) {
				nodes {
					productID
					productGroupID
					name
					description
					monthly
					quarterly
					semiannually
					annually
					biennially
					triennially
				}
			}
		}
	`);
	return (
		<Layout>
			<SEO
				keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
				title="Easy SEO Tools"
				description="marketgoo helps Small Businesses grow online by providing easy SEO tools to increase traffic and search rankings. We partner with telcos, hosting companies & SMB providers, offering a turnkey white label solution."
				image={"/hostylus-card-preview.jpg"}
			/>
			<Landing
				header=" Get The Best SEO Tools For your Website"
				desc="Get protection against viruses, hackers and even your own code accidentally breaking your site with CodeGuard Website Backup."
				btnURL="#pricing"
				btnText={"Get Started"}
				image={
					<Image
						image={"MarketgooLanding.png"}
						alt={"blue columns & charts showing rankings"}
					/>
				}
				hideSecondary_btn={"hidden"}
			/>
			<section>
				<div className="py-10 text-center">
					<h2 className="text-black font-bold text-3xl my-3">
						Improve your Site&apos;s traffic and Grow your Business with
						marketgoo
					</h2>
					<iframe
						width="700"
						height="449"
						src="https://player.vimeo.com/video/394484913"
						className="mx-auto mobile:hidden"
						allow="autoplay; fullscreen"
						allowFullScreen=""
					></iframe>
					<iframe
						width="350"
						height="289"
						src="https://player.vimeo.com/video/394484913"
						className="mx-auto hidden mobile:block"
						allow="autoplay; fullscreen"
						allowFullScreen=""
					></iframe>
				</div>
			</section>
			<section className="features max-w-6xl mx-auto my-16 mobile:px-3">
				<h2 className="text-4xl text-black font-bold text-center capitalize">
					Steps
				</h2>
				<div className="features grid grid-cols-3 gap-3 flex justify-items-stretch  mt-6 mobile:grid-cols-1 tablet:grid-cols-2 tablet:px-16 py-8 laptop:px-6">
					{Steps.map((feature, key) => (
						<Step
							key={key}
							image={feature.image}
							header={feature.title}
							alt={feature.imageAlt}
						/>
					))}
				</div>
			</section>

			<section className="my-20">
				<ImageLeft
					image={
						<div className="shadow-2xl w-4/5 mx-auto">
							<img src={"/start-improving.gif"} alt={"start-improving"} />
						</div>
					}
					header={<h2 className="  text-4xl font-bold">Start Improving</h2>}
					desc={
						<p className=" font-normal">
							{" "}
							Put your SEO plan into action (with no experts needed) and get a
							monthly progress report
						</p>
					}
					hideShopNow={"hidden"}
					hideLearnMore={"hidden"}
				/>
			</section>
			<section
				id="pricing"
				className="bg-gradient-to-r from-blue-600 via-blue-700 to-blue-900  w-full py-20  mx-auto my-16 mobile:pb-3 tablet:mx-5"
			>
				<h2 className="text-4xl capitalize text-white font-extrabold text-center w-2/3 m-auto mobile:w-full mobile:text-2xl mobile:px-4 tablet:w-5/6 laptop:w-5/6">
					Features & Pricing
				</h2>
				<div className="grid  grid-cols-2 gap-10 flex justify-items-stretch mt-6 max-w-3xl mx-auto mobile:grid-cols-1 tablet:grid-cols-2 tablet:gab-2 mobile:justify-items-center py-8">
					{data.allProducts.nodes.map(function (items, key) {
						var stripedHtml = items.description.replace(/<[^>]+>/g, "");
						var description = stripedHtml.split("\n");
						return (
							<PlanCard
								key={key}
								price={items.monthly}
								header={items.name}
								productID={items.productID}
								perWhat={"MO"}
								myItems={
									<ul
										style={{
											listStyleImage:
												"url('https://ik.imagekit.io/softylus/tick_PLS8tkQNa.jpg')",
											listStylePosition: "inside",
										}}
										className="list-disc"
									>
										{description.slice(1).map((listItem, listIndex) => (
											<LI key={listIndex} LI={listItem} textSize={"text-sm"} />
										))}
									</ul>
								}
							/>
						);
					})}
				</div>
			</section>
			<Reviews slides={IndexSlides} />
			<section className="acc py-16 grid justify-items-center">
				<div className=" px-8 max-w-2xl mobile:px-2">
					<h2 className="text-center text-black mb-16 text-4xl font-bold uppercase">
						Frequently Asked Questions
					</h2>
					{AccordionContent.map((items, index) => (
						<Accordion
							key={index}
							title={items.title}
							content={items.content}
						/>
					))}
				</div>
			</section>
		</Layout>
	);
}

export default marketgoo;
