"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true,
});

var _createClass = (function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === "object" || typeof call === "function")
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError(
      "Super expression must either be null or a function, not " +
        typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true,
    },
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

var Accordion = (function (_Component) {
  _inherits(Accordion, _Component);

  function Accordion(props) {
    _classCallCheck(this, Accordion);

    var _this = _possibleConstructorReturn(
      this,
      (Accordion.__proto__ || Object.getPrototypeOf(Accordion)).call(
        this,
        props
      )
    );

    _this.toggle = function () {
      _this.setState(function (prevState) {
        return {
          open: !prevState.open,
        };
      });
    };

    _this.state = {
      open: !!props.isOpen,
    };
    return _this;
  }

  _createClass(Accordion, [
    {
      key: "componentWillReceiveProps",
      value: function componentWillReceiveProps(nextProps) {
        if (this.props.isOpen !== nextProps.isOpen) {
          this.toggle();
        }
      },
    },
    {
      key: "componentDidUpdate",
      value: function componentDidUpdate(prevProps, prevState) {
        if (prevState.open !== this.state.open && this.props.onToggle) {
          this.props.onToggle(this.state.open);
        }
      },
    },
    {
      key: "render",
      value: function render() {
        return _react2.default.createElement(
          "div",
          { className: ["Accordion", this.props.className || ""].join(" ") },
          _react2.default.createElement(
            "div",
            {
              onClick: this.toggle,
              className: [
                "Accordion__header",
                !this.state.open ? "Accordion__header--collapsed" : "",
                this.props.headerClass || "",
              ].join(" "),
            },
            this.props.title,
            this.props.headerChildren
              ? this.props.headerChildren(this.state.open)
              : null
          ),
          _react2.default.createElement(
            "div",
            {
              className: this.state.open
                ? this.props.contentWrapperClass
                  ? "Accordion__body " + this.props.contentWrapperClass
                  : "Accordion__body"
                : "Accordion__body Accordion__body--collapsed",
            },
            this.props.children
          )
        );
      },
    },
  ]);

  return Accordion;
})(_react.Component);

Accordion.propTypes = {
  headerClass: _propTypes2.default.string,
  contentWrapperClass: _propTypes2.default.string,
  isOpen: _propTypes2.default.bool,
  onToggle: _propTypes2.default.func,
  headerChildren: _propTypes2.default.func,
};
exports.default = Accordion;
