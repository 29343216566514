import React from "react";
import AliceCarousel from "react-alice-carousel";
import Slide from "./carouselSlide";
import "react-alice-carousel/lib/alice-carousel.css";
import PropTypes from "prop-types";

const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  768: { items: 3 },
  1024: { items: 4 },
};

export default function Reviews({ heading, sub_heading, slides }) {
  // Convert heading and sub_heading to strings if they're arrays
  const headingText = Array.isArray(heading) ? heading[0] : heading;
  const subHeadingText = Array.isArray(sub_heading) ? sub_heading[0] : sub_heading;

  return (
    <section>
      <svg
        style={{ margin: "auto" }}
        width="60"
        height="40"
        viewBox="0 0 40 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* SVG path data */}
      </svg>
      <h2 className="capitalize text-black text-2xl font-bold text-center">
        {headingText}     
      </h2>
      <p className="text-gray-400 text-lg text-center">{subHeadingText}</p>

      <div>
        <AliceCarousel
          mouseTracking
          infinite={true}
          disableDotsControls={true}
          disableButtonsControls={true}
          autoPlay={true}
          autoPlayInterval={2000}
          responsive={responsive}
          items={slides.map((item, key) => (
            <Slide
              key={key}
              name={item.name}
              message={item.message}
              image={item.image}
            />
          ))}
        />
      </div>
    </section>
  );
}

Reviews.propTypes = {
  slides: PropTypes.array.isRequired,
  heading: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  sub_heading: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
};