import React from "react";
import PropTypes from "prop-types";
import Star from "./star";

export default function carouselSlide(props) {
  return (
    <div className=" p-4 m-3 cursor-default border-solid border-gray-200 border-2 rounded my-16 ">
      <Star /> <Star /> <Star /> <Star /> <Star />
      <hr className="mb-6 mt-2" />
      <svg
        width="40"
        height="25"
        viewBox="0 0 40 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.03163 0C14.1388 0 18.1713 4.02973 18.1713 8.9999C18.1713 13.9701 13.9768 17.9998 8.86971 17.9998C8.81862 17.9998 8.74155 17.9931 8.69047 17.9922C10.7098 19.5571 13.246 20.5001 16.0221 20.5001C17.2993 20.5001 18.3271 21.5079 18.3271 22.75C18.3271 23.9921 17.5054 25 16.2282 25C7.163 24.9992 0.000219345 17.822 0.000219345 8.9999C0.000219345 8.99821 0.000219345 8.99653 0.000219345 8.994C0.000219345 4.02636 3.92624 0 9.03163 0Z"
          fill="#D3D3D3"
          fillOpacity="0.5"
        />
        <path
          d="M30.6915 0C35.7986 0 39.8381 4.02973 39.8381 8.9999C39.8381 13.9701 35.6471 17.9998 30.5391 17.9998C30.488 17.9998 30.4127 17.9931 30.3616 17.9922C32.3809 19.5571 34.918 20.5001 37.6941 20.5001C38.9713 20.5001 40 21.5079 40 22.75C40 23.9921 39.1653 25 37.8881 25C28.8229 24.9992 21.6471 17.822 21.6471 8.9999C21.6471 8.99821 21.6471 8.99653 21.6471 8.994C21.6471 4.02636 25.5861 0 30.6915 0Z"
          fill="#D3D3D3"
          fillOpacity="0.5"
        />
      </svg>
      <div
        id="scrollingReviews"
        className="scrollingReviews cursor-default text-gray-400 text-md my-6"
      >
        {props.message}
      </div>
      <hr className="my-6" />
      <div className="flex justify-items-start">
        <img
          style={{ height: 4 + "rem" }}
          className="cursor-default rounded-full w-16 h-16 max-h-16 mobile:max-h-16 mobile:h-16"
          src={props.image}
        />
        <div className="py-2 pl-1">
          <h3 className="text-black font-bold text-md cursor-default">
            {props.name}
          </h3>
        </div>
      </div>
    </div>
  );
}
carouselSlide.propTypes = {
  image: PropTypes.string.isRequired, // must be a string and defined
  name: PropTypes.string.isRequired, // must be a string and defined
  message: PropTypes.string.isRequired,
};
